import { useFlags, type LDFlagSet } from 'launchdarkly-react-client-sdk';
import Cookies from 'js-cookie';

interface FeatureFlags extends LDFlagSet {
  // Please keep alphabetized
  autoOktaLogout: boolean;
  externalServicesManagement: boolean;
  identityAccountSettingsAvatar: boolean;
  identityManagementEditTeamMemberPhone: boolean;
  platformNotificationsPaymentsComingSoon: boolean;
  platformNotificationsPaymentsSection: boolean;
  platformNotificationsPreferencesLink: boolean;
}

/**
 * A wrapper over `useFlags` that supports cookie overrides
 * that uses LDFlagSet (type from LD) to assert the same
 * predictable return
 */
export function useAppFlags<T extends FeatureFlags>(): T & {
  __loading__: boolean;
} {
  // Get flags from Launch Darkly
  const rawFlags = useFlags<T>();

  let flags: T = rawFlags;
  try {
    // Load flags from cookies for overriding the LD flags manually
    const overrideFlags: T = JSON.parse(Cookies.get('side-ld-flags') || '{}');
    flags = {
      ...rawFlags,
      ...overrideFlags,
    };
  } catch (error) {
    // TODO: [PLAT-1731] use standardized logger module in ui
    // eslint-disable-next-line no-console
    console.error('Error getting the launchdarkly flags', { error });
  }

  const loading = Object.keys(flags).length === 0;

  return { ...flags, __loading__: loading };
}
