import { useSession } from '@side/next-auth/react';
import { type LDContext, LDProvider } from 'launchdarkly-react-client-sdk';
import { type ReactNode } from 'react';
import { LoadingFallback } from 'src/components/LoadingFallback';
import { datadogRum } from '@datadog/browser-rum';
import { getOrCreateClientId } from './utils';

type LaunchDarklyProps = {
  children: ReactNode;
};
/**
 * Initialize the Launch Darkly React SDK
 */
export function LaunchDarkly(props: LaunchDarklyProps) {
  const { data, status } = useSession();

  if (status === 'loading') {
    return <LoadingFallback />;
  }

  // TODO: [PLAT-3129] Remove legacyExternalId once we switch 100% to Identity's UID
  const { id, email, displayName, legacyExternalId } = data?.user ?? {};
  const anonymous = !legacyExternalId && !id;

  let userContext: LDContext = {
    anonymous,
    key: 'unknown',
    custom: { clientId: getOrCreateClientId() },
  };

  if (!anonymous) {
    userContext = {
      ...userContext,
      email: email ?? undefined,
      key: legacyExternalId ?? id,
      name: displayName ?? undefined,
    };
  }

  // https://docs.cypress.io/faq/questions/using-cypress-faq#Is-there-any-way-to-detect-if-my-app-is-running-under-Cypress
  if (typeof window !== 'undefined' && window.Cypress) {
    userContext.anonymous = false;
    userContext.key = 'cypress';
  }

  return (
    <LDProvider
      clientSideID={process.env.NEXT_PUBLIC_LAUNCHDARKLY_SDK_ID || ''}
      context={userContext}
      options={{
        bootstrap: 'localStorage',
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key, detail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            },
          },
        ],
      }}
      {...props}
    />
  );
}
