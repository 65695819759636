import { type EventPayload } from '@side/analytics';

export enum AnalyticsEventName {
  LogoutRedirect = 'logout_redirect',
  SSOPlatformLogin = 'sso_platform_login',
  SSOOktaLogin = 'sso_okta_login',
  GoogleLogin = 'google_login',
  SSOLoginClick = 'sso_login_click',
  GoogleLoginClick = 'google_login_click',
  TeamNotFound = 'team_not_found',
  TeamMemberNotFound = 'team_member_not_found',
  ExternalServiceAddClick = 'external_service_add_click',
  ExternalServiceSaveClick = 'external_service_save_click',
  ExternalServiceRemoveClick = 'external_service_remove_click',
  ExternalServiceAddUserClick = 'external_service_add_user_click',
  TeamDetailTabMount = 'team_detail_tab_mount',
  TeamDetailTabUnmount = 'team_detail_tab_unmount',
}

export enum AnalyticsEvent {
  OktaLoginSuccess,
  OktaLoginFailure,
  GoogleLoginFailure,
  OktaLoginClick,
  OktaAutomaticLogin,
  GoogleLoginClick,
  OktaLogoutSuccess,
  OktaLogoutFailure,
  OktaFullLogout,
  TeamNotFound,
  TeamMemberNotFound,
  ExternalServiceAddClick,
  ExternalServiceSaveClick,
  ExternalServiceRemoveClick,
  ExternalServiceAddUserClick,
  TeamDetailTabMount,
  TeamDetailTabUnmount,
}

type EventNames = {
  [key in AnalyticsEvent]: AnalyticsEventName;
};

type EventTypePayloads = {
  [key in AnalyticsEvent]: EventPayload & Record<string, unknown>;
};

export const AnalyticsEventNames: EventNames = {
  [AnalyticsEvent.OktaFullLogout]: AnalyticsEventName.LogoutRedirect,
  [AnalyticsEvent.OktaLogoutSuccess]: AnalyticsEventName.LogoutRedirect,
  [AnalyticsEvent.OktaLogoutFailure]: AnalyticsEventName.LogoutRedirect,
  [AnalyticsEvent.OktaLoginSuccess]: AnalyticsEventName.SSOPlatformLogin,
  [AnalyticsEvent.OktaLoginFailure]: AnalyticsEventName.SSOPlatformLogin,
  [AnalyticsEvent.GoogleLoginFailure]: AnalyticsEventName.GoogleLogin,
  [AnalyticsEvent.OktaLoginClick]: AnalyticsEventName.SSOLoginClick,
  [AnalyticsEvent.GoogleLoginClick]: AnalyticsEventName.GoogleLoginClick,
  [AnalyticsEvent.OktaAutomaticLogin]: AnalyticsEventName.SSOOktaLogin,
  [AnalyticsEvent.TeamNotFound]: AnalyticsEventName.TeamNotFound,
  [AnalyticsEvent.TeamMemberNotFound]: AnalyticsEventName.TeamMemberNotFound,
  [AnalyticsEvent.ExternalServiceAddClick]:
    AnalyticsEventName.ExternalServiceAddClick,
  [AnalyticsEvent.ExternalServiceSaveClick]:
    AnalyticsEventName.ExternalServiceSaveClick,
  [AnalyticsEvent.ExternalServiceRemoveClick]:
    AnalyticsEventName.ExternalServiceRemoveClick,
  [AnalyticsEvent.ExternalServiceAddUserClick]:
    AnalyticsEventName.ExternalServiceRemoveClick,
  [AnalyticsEvent.TeamDetailTabMount]: AnalyticsEventName.TeamDetailTabMount,
  [AnalyticsEvent.TeamDetailTabUnmount]:
    AnalyticsEventName.TeamDetailTabUnmount,
};

export const AnalyticsEventPayloads: EventTypePayloads = {
  [AnalyticsEvent.OktaLoginSuccess]: {
    eventContainer: 'sso_platform_identity',
    eventTarget: 'list_view',
    eventType: 'load',
    task: 'redirect',
    redirect: true,
  },
  [AnalyticsEvent.OktaLoginFailure]: {
    eventContainer: 'sso_platform_identity',
    eventTarget: 'list_view',
    eventType: 'load',
    task: 'redirect',
    redirect: false,
  },
  [AnalyticsEvent.OktaLogoutSuccess]: {
    eventContainer: 'logout_identity',
    eventTarget: 'logout_view',
    eventType: 'load',
    task: 'redirect',
    redirect: true,
  },
  [AnalyticsEvent.OktaFullLogout]: {
    eventContainer: 'logout_identity',
    eventTarget: 'logout_view',
    eventType: 'load',
    task: 'redirect',
    redirect: true,
    multi_user: true,
  },
  [AnalyticsEvent.OktaLogoutFailure]: {
    eventContainer: 'logout_identity',
    eventTarget: 'logout_view',
    eventType: 'load',
    task: 'redirect',
    redirect: true,
  },
  [AnalyticsEvent.GoogleLoginFailure]: {
    eventContainer: 'google_identity',
    eventTarget: 'list_view',
    eventType: 'load',
    task: 'redirect',
    redirect: false,
  },
  [AnalyticsEvent.OktaLoginClick]: {
    eventContainer: 'sso_login_modal',
    eventTarget: 'sign_in_button',
    eventType: 'click',
    task: 'login',
  },
  [AnalyticsEvent.GoogleLoginClick]: {
    eventContainer: 'google_login_modal',
    eventTarget: 'sign_in_button',
    eventType: 'click',
    task: 'login',
  },
  [AnalyticsEvent.OktaAutomaticLogin]: {
    eventContainer: 'sso_okta_identity',
    eventTarget: 'list_view',
    eventType: 'load',
    task: 'redirect',
  },
  [AnalyticsEvent.TeamNotFound]: {
    eventContainer: 'team_detail_identity',
    eventTarget: 'team_not_found',
    eventType: 'load',
    task: 'redirect',
    redirect: false,
  },
  [AnalyticsEvent.TeamMemberNotFound]: {
    eventContainer: 'team_member_detail_identity',
    eventTarget: 'team_member_not_found',
    eventType: 'load',
    task: 'redirect',
    redirect: false,
  },
  [AnalyticsEvent.ExternalServiceAddClick]: {
    eventContainer: 'add_external_service_modal',
    eventTarget: 'add_service_button',
    eventType: 'click',
    redirect: false,
    task: 'add',
  },
  [AnalyticsEvent.ExternalServiceSaveClick]: {
    eventContainer: 'external_service_detail',
    eventTarget: 'save_service_button',
    eventType: 'click',
    redirect: false,
    task: 'update',
  },
  [AnalyticsEvent.ExternalServiceRemoveClick]: {
    eventContainer: 'remove_external_service_modal',
    eventTarget: 'remove_button',
    eventType: 'click',
    task: 'remove',
    redirect: false,
  },
  [AnalyticsEvent.ExternalServiceAddUserClick]: {
    eventContainer: 'add_external_service_user_modal',
    eventTarget: 'add_user_button',
    eventType: 'click',
    task: 'add',
    redirect: false,
  },
  [AnalyticsEvent.TeamDetailTabMount]: {
    eventContainer: 'team_detail_identity',
    eventTarget: 'team_view',
    eventType: 'load',
    task: 'view',
  },
  [AnalyticsEvent.TeamDetailTabUnmount]: {
    eventContainer: 'team_detail_identity',
    eventTarget: 'team_view',
    eventType: 'load',
    task: 'view',
  },
};
