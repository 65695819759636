import Script from 'next/script';

// NOTE: API Key is meant for front end and is not private
const mazeApiKey = process.env.NEXT_PUBLIC_MAZE_API_KEY;

/**
 * Setup Maze.io script as described in their docs
 * @see https://help.maze.co/hc/en-us/articles/9800356063891
 * @returns React component which renders next/script with maze script
 */
export function SetupMaze() {
  // Exit if key does not exist - this prevents running on prod while testing
  if (!mazeApiKey) return null;
  return (
    <Script
      id="setup-maze"
      dangerouslySetInnerHTML={{
        __html: `
      (function (m, a, z, e) {
        var s, t;
        try {
          t = m.sessionStorage.getItem('maze-us');
        } catch (err) {}
      
        if (!t) {
          t = new Date().getTime();
          try {
            m.sessionStorage.setItem('maze-us', t);
          } catch (err) {}
        }
      
        s = a.createElement('script');
        s.src = z + '?t=' + t + '&apiKey=' + e;
        s.async = true;
        a.getElementsByTagName('head')[0].appendChild(s);
        m.mazeUniversalSnippetApiKey = e;
      })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', String("${mazeApiKey}"))`,
      }}
      onError={(err) => {
        // eslint-disable-next-line no-console
        console.error(`Error loading Maze script: ${err.message}`);
      }}
    />
  );
}
