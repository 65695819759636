import { setContext } from '@apollo/client/link/context';
import { getSession } from '@side/next-auth/react';

export const authLink = setContext(async (request, { headers = {} }) => {
  const session = await getSession();

  if (!session?.accessToken) {
    throw new Error('User does not have an access token from Okta.');
  }

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${session.accessToken}`,
    },
  };
});
