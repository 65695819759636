import Cookies from 'js-cookie';
import { v4 } from 'uuid';

/**
 * Gets or creates a unique clientId for a user in the `side.client_id` cookie.
 * Defaults to a DataDog session ID or generates a random UUID if no session ID exists.
 *
 * @returns Side's clientId for LaunchDarkly
 */
export function getOrCreateClientId() {
  const SIDE_COOKIE_NAME = 'side.client_id';
  const DD_COOKIE_NAME = '_dd_s';
  // Cookies.get() may return undefined on server-side
  // but is not reflected in its types
  // TODO: [PLAT-2381] Handle SSR
  const cookies = Cookies.get() || {};
  const sideCookie = cookies[SIDE_COOKIE_NAME];
  const sideId = JSON.parse(sideCookie || 'null')?.clientId;
  const dataDogCookie = cookies[DD_COOKIE_NAME];
  const dataDogId = new URLSearchParams(dataDogCookie).get('id');
  const clientId = sideId || dataDogId || v4();

  Cookies.set(SIDE_COOKIE_NAME, JSON.stringify({ clientId }), { expires: 365 });
  return clientId;
}
