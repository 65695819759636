import { type ReactNode, useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { PantryConfigProvider, type PantryConfig } from '@side/pantry';
import { useAppFlags } from '../launchdarkly/app-flags';
import { useTrackExternalEvent } from '../analytics/use-track-event';

export function PantryProvider({ children }: { children: ReactNode }) {
  const featureFlags = useAppFlags();
  const nextPathname = usePathname();
  const trackEvent = useTrackExternalEvent();

  const config: PantryConfig = useMemo(
    () => ({ featureFlags, nextPathname, trackEvent }),
    [featureFlags, nextPathname, trackEvent],
  );

  return (
    <PantryConfigProvider config={config}>{children}</PantryConfigProvider>
  );
}
