import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useRef, useState } from 'react';

// see https://github.com/reside-eng/admin-ui/pull/105#discussion_r641845365
// for details about this hook vs fetchMore
export function usePaginatedQuery(
  query,
  queryOptions = {},
  paginationOptions = {},
) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    paginationOptions.rowsPerPage || 100,
  );
  const [after, setAfter] = useState(null);
  const [before, setBefore] = useState(null);

  const { variables: additionalVariables } = queryOptions;
  const options = {
    ...queryOptions,
    variables: {
      last: before ? rowsPerPage : undefined,
      first: before ? undefined : rowsPerPage,
      before,
      after,
      ...additionalVariables,
    },
  };

  const queryResult = useQuery(query, options);
  const { data, error, loading } = queryResult;

  const [field] = Object.values(data || {});
  const { endCursor, startCursor } = field?.pageInfo || {};
  const previousCount = useRef(field?.totalCount);

  useEffect(() => {
    if (field?.totalCount) {
      previousCount.current = field.totalCount;
    }
  }, [field?.totalCount]);

  const resetPagination = useCallback(() => {
    setPage(0);
    setAfter(null);
    setBefore(null);
  }, []);

  const handleRowsPerPageChange = useCallback(
    (nextLimit) => {
      resetPagination();
      setRowsPerPage(nextLimit);
    },
    [resetPagination],
  );

  const handlePageChange = useCallback(
    async (nextPage) => {
      const nextAfter = nextPage > page ? endCursor : null;
      const nextBefore = nextPage > page ? null : startCursor;
      if (!nextAfter && !nextBefore) {
        return;
      }
      setPage(nextPage);
      setAfter(nextAfter);
      setBefore(nextBefore);
    },
    [page, endCursor, startCursor],
  );
  return [
    { data, error, loading },
    {
      count: field?.totalCount ?? previousCount.current,
      page,
      rowsPerPage,
      handlePageChange,
      handleRowsPerPageChange,
      resetPagination,
    },
  ];
}
